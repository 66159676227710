/* Home.css */
.Home {
  background: linear-gradient(to bottom right, #070547, #020004, #1b033a);
  min-height: 100vh;
  position: relative;
}

header {
  padding: 4rem 1rem;
  text-align: center;
}

header .flex {
  justify-content: center;
  align-items: center;
}

.text-white {
  color: #ffffff;
}

.bg-blue-500 {
  background-color: #3b82f6;
}

.bg-blue-700 {
  background-color: #1d4ed8;
}

.hover\:bg-blue-700:hover {
  background-color: #1d4ed8;
}

.transition {
  transition: all 0.3s ease;
}

.duration-300 {
  transition-duration: 300ms;
}

.rounded {
  border-radius: 0.375rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.grid {
  display: grid;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.sm\:grid-cols-2 {
  @media (min-width: 640px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.gap-4 {
  gap: 1rem;
}

.lg\:gap-6 {
  @media (min-width: 1024px) {
    gap: 1.5rem;
  }
}

.lg\:mt-[50px] {
  @media (min-width: 1024px) {
    margin-top: 50px;
  }
}

.mt-[20px] {
  margin-top: 20px;
}
